@media (min-width: 1200px){

}


/*Notebooks and Small Laptops*/
@media (min-width: 1025px){
    .introLogo {
        width:20% !important;
    }
}

/*iPad Landscape Mode and Large Tablets */
@media (max-width: 1024px){

    .hero .container, #intro .container {
        padding-top:10% !important;
    }    
    #text-separator-testimonials h3 span {
        padding:25px 35px !important;
    }
    .simpleServiceContent h1 {
        font-size: 1.5em !important;
        letter-spacing: 2px !important;
    }
    .serviceIconWrapper {
        padding:0px !important;
    }
    .gallery .grid-sizer-four-columns,
    .gallery .four-columns{
        width:50%;
    }

}

/*Small Desktop and Very Small Notebook */
@media (max-width: 992px){

    .widget:first-child{
        margin-top:100px;
    }

    .serviceBox h2 span {
        letter-spacing:5px;
        font-size:22px;
    }

    #owl-clients{
        margin-top:50px;
    }

    #text-separator-testimonials h3{
        text-align:center;
        margin-bottom:50px;
    }

    .IntroBoxes{
        height:250px;
    }



}

/*Medium Tablets */
@media (max-width: 900px){

    .navbar-nav > li > a {
        letter-spacing: 1px;
        padding-left: 10px;
        padding-right: 10px;
    }
.diagonalBanner {    
    background-image: none;
    background-size: cover;
}
.dualLayout {
    background-color:#f3f3f3 !important;
    height:auto !important;
     background: -webkit-linear-gradient(25deg, #ececec 60%, #ececec 50%) !important;
    /*background-image: url("img/services/web-development8-hero.jpg");*/
  }    
}


/*iPad Portrait Mode, iPad Mini and Small Tablets */
@media (max-width: 768px){

    .IntroBoxes{
        height:100px;
    }
    

    .ThickBorder .left,
    .ThickBorder .right,
    .ThickBorder .bottom
    {
        display:none;
    }

    .serviceBox h2 span {
        letter-spacing:5px;
        font-size:18px;
    }

    .textOverlay{
        padding-bottom:100px;
        padding-top:100px;
        margin-left:0px;
        z-index:6;
    }


    #portfolio .captionWrapper .valign{
        padding-top:130px;
    }

    .preloaderLogo{
        min-width: 100px;
        max-width: 100px;
        width: 100px;
    }

    .ip-header {
        min-height: 120px;
    }

    .affix .navbar-collapse {
        background:transparent;
    }

    .navbar-fixed-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
        max-height: 100%;
    }

    .navbar-nav>li>.dropdown-menu {
        text-align: center;
    }

    .portfolioFilters {
        height: 220px;
    }

    .memberDescription{
        text-align: center;
    }


    .memberParagraph{
        padding-right:0px;
    }

    .clientsIntro{
        text-align: center;
    }

    #text-separator, #text-separator-clients, #text-separator-testimonials, #text-separator-timers {
        padding-left: 15px;
    }

    .text-separator-title{
        text-align: center;
    }

    .tableWrapper{
        margin-top:15px;
        margin-bottom:15px;
    }

    .gallery .gallery-inner, .gallery .grid-sizer {
        width: 50%;
    }

    .singleProjectInfo ul {
        border-left: 0;
        text-align: center;
    }

    #text-separator-clients h2,
    #text-separator-testimonials h2{
        font-size:20px;

    }

    #team .container{
        padding-bottom:200px;
    }

    #countdown .countdown-amount{
        font-size: 30px;
    }

    #countdown .countdown-section{
        border-left:none;
    }

    .blogPost,
    .grid-sizer-blog-3{
        width: 50%;
    }


    .gallery .grid-sizer-four-columns,
    .gallery .four-columns{
        width:50%;
    }


    .EcommerceNavbarRow .PaddingVertical30{
        padding-top:15px;
        padding-bottom:15px;
    }

    .EcommerceLogo{
        text-align:center;
        display:inline;
    }

    .EcommerceLogo h2{
        font-size:32px;
    }

    .cbp .cbp-item {
        width: 100% !important;
        float: left;
        position: relative !important;
        left:0px !important;
        top: 0px !important;
        height: 250px;
    }
    .cbp {
        height:750px !important;
    }

    .CartDropdown {
        left: 50%;
        right: auto;
        text-align: center;
        transform: translate(-50%, 0);
    }

    #accordion-filters{
        margin-bottom:30px;
    }

    .gallery-ecommerce .grid-sizer-three-columns, .gallery-ecommerce .three-columns{
        width:50%;
    }

    .navbar-right .dropdown-menu{
        left:-100px;
    }

    .NavbarEcommerceBottomWrapper .navbar-nav > li > a{
        letter-spacing:0;
    }
        .serviceBox {
        width: 100%;
    }
     .container{
        width:auto;
    }
}

/*iPad Portrait Mode, iPad Mini and Small Tablets */
@media (max-width: 767px) {
    h1 {
        font-size:30px !important;
        letter-spacing: 3px !important;
    }
    .hero h1, #intro h1 {
        font-size:2.1em !important;
        letter-spacing: 5px !important;
        padding:0px 0 !important;
        color:#fff !important;
    }
    .hero h2,#intro h2 {
        font-size:1.2em !important;
        letter-spacing: 4px !important;
    }
    h2 {
        letter-spacing: 5px !important;
    }
    .sectionIntro h2 span {
        padding:20px !important;

    } 
    #servicesSimple .hero h1 {
        padding:20px 0px !important;
        font-size:20px !important;
        margin-top: 50px;
    }
   #servicesSimple .hero h1 span {
        font-size:20px !important;
    }     

    #intro h3 {
        font-size:14px !important;
    }  
    .textOverlay {
        margin-left:0px !important;
    }
    .navbar-default .navbar-collapse {
        border-color: #e7e7e7;
        background: rgba(0, 0, 0, 0.9);
        text-align: center;
    }
    #text-separator-clients h3 span {
        padding:20px 30px !important;
        font-size:22px !important;
        letter-spacing: 5px !important;
    }
    .container.services section {
        padding:0% !important;
        border-bottom: solid #ddd 1px;
    }
    .navbar-brand {
    padding:15px 0px !important;
    }
    .serviceIconWrapper {
        height:250px !important;
        padding:20px !important;
    }
}

/*Medium Smart Phones v2*/
@media screen and (max-width: 640px){
    .serviceBox h2 span {
        padding: 0;
        border: 0;
    }
    #singlePageIntro .container {
        padding: 30px;
    }
    .tableWrapper {
        padding: 30px;
        padding-bottom:50px;
    }
}

@media screen and (max-width: 600px){
    #callNow h2 {
        padding:50px 0px !important;
    }
    #text-separator-testimonials h3 {
        padding-top:50px !important; 
    }
    #text-separator-testimonials h3 span {
        padding:20px 25px !important;
        font-size:20px !important;
        letter-spacing: 1px !important; 
    }
    .navbar-default {
        padding-left: 0px;
        padding-right: 0px;
    }

    .ServiceWrapperTitle{
        display:none;
    }

    .ServiceTitleMobile{
        display:block;
        margin-bottom:20px;
    }
}

@media screen and (max-width: 600px) and (orientation : landscape){
    #intro .separator{
        margin-top:15px;
        margin-bottom:15px;
        width:100px;
        max-width:100px;
    }

    .ip-logo img{
        opacity: 0;

    }
}


@media screen and (max-width: 480px){
    .introLogo {
        width:36% !important;
        padding-top: 120px;
    }
    .welcomeImage {
        margin-right: -80px !important;
        padding-top: 20px;
    }
    .serviceIconWrapper {
        padding: 76px 20px 0px 20px !important;
    }
    .serviceBox {
        padding: 0px !important;
    }
    .gallery-ecommerce .grid-sizer-three-columns, .gallery-ecommerce .three-columns{
        width:100%;
    }


    .gallery .grid-sizer-two-columns,
    .gallery .two-columns{
        width:100%;
    }


    .topLine {
        padding: 20px;
    }

    section .container {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    h1 {
        font-size: 24px;
        letter-spacing: 6px;
    }

    h2 {
        font-size: 16px;
        text-transform: uppercase;
        letter-spacing: 4px;
    }

    h4 {
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 2px;
    }
    .sectionIntro h2 span {
        padding: 50px 25px;
        display: block;    
    }

    .portfolioIntro h2 span {
        padding: 50px 25px;
        display: block;
    }

    #intro .separator {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    #intro .separator {
        width: 100px;
        max-width: 100px;
    }

    #intro p {
        font-size: 12px;
    }

    .navbar-default {
        padding-left: 0px;
        padding-right: 0px;
    }

    .portfolioFilters {
        height: 280px;
    }

    .gallery .gallery-inner, .gallery .grid-sizer {
        width: 100%;
    }

    .serviceBox {
        padding: 20px;
    }
    .simpleServiceContent h1 {
        font-size: 1.2em !important;
        letter-spacing: 1px !important;
    }

}



/*google nexus ish*/
@media screen and (max-width: 480px) {
    .gallery .grid-sizer-four-columns,
    .gallery .four-columns{
        width:100%;
    }

    #text-separator-clients h3 span {
        padding:0;
        border:none;
    }


    #text-separator-testimonials h3 span {
        padding:0;
        border:none;
    }


}
/*Other Major Small Smart Phones*/
@media screen and (max-width: 360px){

    .blogPost,
    .grid-sizer-blog-3{
        width: 100%;
    }

    .blogPost2,
    .grid-sizer-blog-2{
        width: 100%;
    }


    section .container {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    /*.textOverlay {*/
    /*margin-left: -100px;*/
    /*}*/


    .portfolioFilters {
        height: 450px;
    }



    .tableWrapper {
        padding: 10px;
        padding-bottom:30px;
    }

    .listwrapper{
        width:100%;
    }
}

/*iPhone Portrait Mode and Very Small Mobile Phones*/
@media screen and (max-width: 320px) {


}