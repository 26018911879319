/* preloader.css */
.ip-header {
    position: fixed;
    top: 0;
    z-index: 1050;
    min-height: 480px;
    width: 100%;
    height: 100%;
    background: #333;
}

.ip-header h1 {
    margin: 0;
}

.ip-logo,
.ip-loader {
    position: absolute;
    left: 0;
    width: 100%;
    opacity: 0;
    cursor: default;
    pointer-events: none;
}

.ip-logo {
    top: 0;
    height: 100%;
    -webkit-transform: translate3d(0,25%,0);
    transform: translate3d(0,25%,0);
}

.ip-loader {
    bottom: 35%;
}

.ip-header .ip-inner {
    display: block;
    margin: 0 auto;
}

.ip-header .ip-logo svg {

}

.ip-header .ip-logo svg path {
    fill: #046674;
}

.ip-header .ip-loader svg path {
    fill: none;
    stroke-width: 6;
}

.ip-header .ip-loader svg path.ip-loader-circlebg {
    stroke: #333;
}

.ip-header .ip-loader svg path.ip-loader-circle {
    -webkit-transition: stroke-dashoffset 0.2s;
    transition: stroke-dashoffset 0.2s;
    stroke: #fff;
}


/* Animations */

/* Initial animation of header elements */
.loading .ip-logo,
.loading .ip-loader {
    opacity: 1;
    -webkit-animation: animInitialHeader 1s cubic-bezier(0.7,0,0.3,1) both;
    animation: animInitialHeader 1s cubic-bezier(0.7,0,0.3,1) both;
}

.loading .ip-loader {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

@-webkit-keyframes animInitialHeader {
    from { opacity: 0; -webkit-transform: translate3d(0,800px,0); }
}

@keyframes animInitialHeader {
    from { opacity: 0; -webkit-transform: translate3d(0,800px,0); transform: translate3d(0,800px,0); }
}

/* Header elements when loading finishes */
.loaded .ip-logo{
    opacity: 1;

}


.loaded .ip-loader {
    opacity: 1;
}



.loaded .ip-logo {
    -webkit-animation: animLoadedLoader 1s cubic-bezier(0.7,0,0.3,1) forwards;
    animation: animLoadedLoader 1s cubic-bezier(0.7,0,0.3,1) forwards;
}



@-webkit-keyframes animLoadedLogo {
    to { -webkit-transform: translate3d(0,100%,0) translate3d(0,50px,0) scale3d(0.65,0.65,1); }
}

@keyframes animLoadedLogo {
    to { -webkit-transform: translate3d(0,100%,0) translate3d(0,50px,0) scale3d(0.65,0.65,1); transform: translate3d(0,100%,0) translate3d(0,50px,0) scale3d(0.65,0.65,1); }
}

.loaded .ip-loader {
    -webkit-animation: animLoadedLoader 1s cubic-bezier(0.7,0,0.3,1) forwards;
    animation: animLoadedLoader 1s cubic-bezier(0.7,0,0.3,1) forwards;
}

@-webkit-keyframes animLoadedLoader {
    to { opacity: 0; -webkit-transform: translate3d(0,-100%,0) scale3d(0.3,0.3,1); }
}

@keyframes animLoadedLoader {
    to { opacity: 0; -webkit-transform: translate3d(0,-100%,0) scale3d(0.3,0.3,1); transform: translate3d(0,-100%,0) scale3d(0.3,0.3,1); }
}

/* Change the color of the logo */
.loaded .ip-logo svg path {
    -webkit-transition: all 0.5s ease 0.3s;
    transition: all 0.5s ease 0.3s;
    fill: #000;
}

/* Header animation when loading finishes */
.loaded .ip-header {
    -webkit-animation: animLoadedHeader 1s cubic-bezier(0.7,0,0.3,1) forwards;
    animation: animLoadedHeader 1s cubic-bezier(0.7,0,0.3,1) forwards;
}

@-webkit-keyframes animLoadedHeader {
    to { -webkit-transform: translate3d(0,-100%,0); }
}

@keyframes animLoadedHeader {
    to { -webkit-transform: translate3d(0,-100%,0); transform: translate3d(0,-100%,0); }
}

/* Content animations */
.loaded .ip-main h2,
.loaded .ip-main .browser,
.loaded .ip-main .browser .box,
.loaded .codrops-demos {
    -webkit-animation: animLoadedContent 1s cubic-bezier(0.7,0,0.3,1) both;
    animation: animLoadedContent 1s cubic-bezier(0.7,0,0.3,1) both;
}

.loaded .ip-main .browser,
.loaded .ip-main .browser .box:first-child {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
}

.loaded .ip-main .browser .box:nth-child(2) {
    -webkit-animation-delay: 0.15s;
    animation-delay: 0.15s;
}

.loaded .ip-main .browser .box:nth-child(3) {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

@-webkit-keyframes animLoadedContent {
    from { opacity: 0; -webkit-transform: translate3d(0,200px,0); }
}

@keyframes animLoadedContent {
    from { opacity: 0; -webkit-transform: translate3d(0,200px,0); transform: translate3d(0,200px,0); }
}

/* Change layout class for header */
.layout-switch .ip-header {
    position: absolute;
    display:none;
}

/* No JS */
.no-js .ip-header {
    position: relative;
    min-height: 0px;
}

.no-js .ip-header .ip-logo {
    margin-top: 20px;
    height: 180px;
    opacity: 1;
    -webkit-transform: none;
    transform: none;
}

.no-js .ip-header .ip-logo svg path {
    fill: #000;
}